import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Container, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import { SectionWrap } from './style'

const IntroArea = ({ headingStyle, descStyle }) => {
    const getHistoryIntro = useStaticQuery(graphql`
        query GET_ABOUT_SIGNPUNS {
            aboutSignpunJson {
                title
                id
                desc1
                desc2
                desc3
                desc4
                desc5
                desc6
                desc7
              }
        }
    `)
    const { aboutSignpunJson: { title, desc1,desc2,desc3,desc4,desc5,desc6,desc7 } } = getHistoryIntro
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        {title && <Heading {...headingStyle}>{parse(title)}</Heading>}
                        {desc1 && <Text {...descStyle}>{desc1}</Text>}
                        {desc2 && <Text {...descStyle}>{desc2}</Text>}
                        {desc3 && <Text {...descStyle}>{desc3}</Text>}
                        {desc4 && <Text {...descStyle}>{desc4}</Text>}
                        {desc5 && <Text {...descStyle}>{desc5}</Text>}
                        {desc6 && <Text {...descStyle}>{desc6}</Text>}
                        {desc7 && <Text {...descStyle}>{desc7}</Text>}
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

IntroArea.defaultProps = {
    headingStyle: {
        as: 'h3',
        mb: '20px',
        child: {
            color: 'primary'
        }
    },
    descStyle: {
        maxwidth: '760px',
        m: 'auto'
    }
}

export default IntroArea